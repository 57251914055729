import useBaseUtils from "~/functions/useBaseUtils";
export default defineComponent({
  props: {
    finePrint: {
      type: Array,
      "default": function _default() {
        return ["20% off subscription valid until June 1 2021", "Referral credits distributed to referrer within 30 days of referee subscription. Total credit limited to the subscription you have purchased. Offer valid until June 1 2021"];
      }
    },
    showclose: {
      type: Boolean,
      "default": false
    }
  },
  setup: function setup() {
    var _useBaseUtils = useBaseUtils(),
      refCode = _useBaseUtils.refCode,
      dev = _useBaseUtils.dev,
      I18nFn = _useBaseUtils.I18nFn;
    return {
      refCode: refCode,
      dev: dev,
      I18nFn: I18nFn
    };
  },
  data: function data() {
    return {
      copied: false
    };
  },
  computed: {
    firstReferralParagraph: function firstReferralParagraph() {
      return this.I18nFn("You can now provide friends immediate access to TIKR.com with your personal referral link below.");
    },
    explainReferral: function explainReferral() {
      // default: `You will <b>get 20% of subscriptions purchased by users you refer before June 1st</b><sup>**</sup>`
      return this.I18nFn("As always, please reach out to <a href='mailto:support@tikr.com'>support@tikr.com</a> if you have any feedback or questions");
    },
    refLink: function refLink() {
      var dev = this.dev;
      var refCode = this.refCode;
      return dev ? "https://devs.tikr.com/register?ref=".concat(refCode) : "https://app.tikr.com/register?ref=".concat(refCode);
    },
    tooltipMsg: function tooltipMsg() {
      var copied = this.copied;
      return copied ? this.I18nFn("Link copied to clipboard") : this.I18nFn("Click to copy to clipboard");
    }
  },
  methods: {
    copyLink: function copyLink() {
      // const link = document.getElementById("refLink").innerText
      var el = document.createElement("textarea");
      el.value = this.refLink;
      try {
        // copy the referal link to the clipboard here
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
        this.copied = true;
        console.log("copied: ", this.refLink);
      } catch (error) {
        if (this.dev) {
          console.log("copy link error: ", error);
        } // perform the backup copyLink logic?
      }
    },
    makeStars: function makeStars(i) {
      if (i >= 0) {
        var stars = "*";
        for (var j = 0; j < i; j++) {
          stars = stars + "*";
        }
        return stars;
      }
    }
  }
});